// config.js


const API_URL = 'https://terionapi.quantanics.in/';
const API_URL_CLIENT = 'https://terion.quantanics.in/';

// const API_URL = 'http://localhost:4000/';
// const API_URL_CLIENT = 'http://localhost:3001/';



const SECRET_KEY = 'edf6537e67f256578bbb90b2adb1617622d6cbe49702b832c99c6feb8cce817c'
const  TIMEOUT = 5 * 60 * 1000;

export { API_URL , API_URL_CLIENT,SECRET_KEY};
